.Mulish {
  font-weight: 400;
  font-family: "Crimson Text";
}

.eifeil-img-mobile {
  display: none;
}

.Homepage {
  position: fixed;
  width: 100vw;
  z-index: 999;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 4px;
  color: #000000 !important;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
  padding-left: 2em;
}

.mobile-view {
  display: none !important;
}

.ant-card-meta-detail {
  overflow: visible !important;
}

.react-responsive-modal-modal {
  max-width: 800px !important;
  width: 400px !important;
  display: inline-block;
  text-align: center !important;
  vertical-align: middle;
  background: #1B181A !important;
  box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
  margin: 1.2rem;
  padding: 1.2rem;
  position: relative;
  border-radius: 1.5em;
  overflow-y: auto;
}

.react-responsive-modal-closeButton {
  fill: rgb(255, 255, 255) !important;
}

.bm-item,
.sub-item {
  font-size: 20px;
  color: rgb(0, 0, 0);
  display: block;
  padding: 14px 28px;
  cursor: pointer;
}

.mulish-header {
  text-align: center;
  font-weight: 400;
  font-family: "Crimson Text";
}

.button-hover:hover {
  background-color: rgb(0, 0, 0) !important;
  color: white !important;
  padding: 1em !important;
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
  border-radius: 3em !important;
}

.mulish-paragraph {
  font-weight: 400;
  font-family: "Crimson Text";
}

.mulish-paragraph-small {
  font-weight: 400;
  font-family: "Crimson Text";
}

.Mulish-center {
  font-weight: 400;
  font-family: "Crimson Text";
}

.Mulish-product-range {
  font-weight: 400;
  font-family: "Crimson Text";
}

.Mulish-footer {
  font-weight: 400;
  font-family: "Crimson Text";
}

.Poppins {
  font-family: "Poppins";
}

.Chela {
  font-family: "Chela One";
}

.Caveat {
  font-family: "Caveat";
}

.Grace {
  font-family: "Covered By Your Grace";
}

.Condiment {
  font-family: "Condiment";
}

.category-card-hover:hover {
  transition: 0.2s;
  filter: invert(1.2);
}

#nav-toggle span {
  display: none;
}

@media only screen and (max-width: 770px) {
  .category-card-hover {
    width: 93vw !important;
  }

  .mobile-display {
    display: none;
  }

  .ant-page-header-heading-extra>* {
    margin-left: 0px !important;
    white-space: unset;
  }

  .header-logo {
    width: 7em !important;
  }

  #nav-toggle span {
    position: absolute;
    right: 0;
    top: 40px;
  }

  #nav-toggle span,
  #nav-toggle span:before,
  #nav-toggle span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 5px;
    width: 35px;
    background: #ffffff;
    position: absolute;
    display: block;
    content: "";
  }

  #nav-toggle span:before {
    top: -10px;
  }

  #nav-toggle span:after {
    bottom: -10px;
  }

  .mulish-header {
    text-align: center;

    line-height: 1;
    font-size: 25px !important;
    font-weight: 400;
    font-family: "Crimson Text";
  }

  .mulish-paragraph {
    font-size: 5vh !important;
    margin-top: 0.2em !important;
    text-align: center;
    line-height: 1;
    font-weight: 400;
    font-family: "Crimson Text";
  }

  .home-col {
    margin: auto;
  }

  .mulish-paragraph-small {
    text-align: center;
    font-weight: 400;
    font-family: "Crimson Text";
  }

  .home-button {
    margin-top: 0.5em !important;
    text-align: center;
    margin: auto;
    line-height: 1;
    font-weight: 400;
    font-family: "Crimson Text";
  }

  .home-img {
    object-fit: cover;
    height: 30em;
    margin-top: 10em !important;
  }

  .about-row {
    margin-top: 0em !important;
    margin: auto;
    text-align: center;
  }

  .about-row-cert {
    margin: auto;
    margin-top: 2em !important;
    text-align: center;
  }

  .Mulish-center {
    padding-right: 0em !important;
    font-weight: 400;
    font-family: "Crimson Text";
  }

  .Mulish-product-range {
    margin-top: -1.5em !important;
    font-weight: 400;
    font-family: "Crimson Text";
  }

  .products-img {
    margin-top: 0em !important;
  }

  .products-img-1 {
    height: 15em !important;
    width: 93vw !important;
    margin: 1em !important;
    margin-bottom: 0em !important;

  }

  .products-img-2 {
    height: 29em !important;
    width: 93vw !important;
    margin: 1em !important;
    margin-top: 0em !important;
    margin-bottom: 0em !important;
  }

  .view-more-button {
    margin-top: -3em !important;
  }

  .hori-line {
    display: none;
  }

  .gallery-banner {
    text-align: center;
  }

  .gallery-banner-img {
    width: 100%;
  }

  .gallery-container {
    margin-top: 3em !important;
  }

  .footer-div {
    height: 30em !important;
  }

  .mobile-view {
    display: inline !important;
  }

  .desktop-view {
    display: none !important;
  }

  .footer-logo {
    text-align: center;
  }

  .Mulish-footer {
    font-weight: 400;
    padding-right: 0 !important;
    font-family: "Crimson Text";
  }

  .footer-links-company {
    width: 85vw !important;
    /* text-align: center !important; */
  }

  .contact-image {
    width: 100vw !important;
    margin-top: 7em !important;
  }

  .gallery-paragraph {
    padding: 0em !important;
    padding-top: 1em !important;
  }

  .Mulish-hidden {
    display: none;
  }

  .eifeil-img-mobile {
    display: inline;
  }

  .eifeil-img {
    display: none;
  }

  .other-button {
    margin-top: 1.5em !important;
    margin-bottom: 1.5em !important;
  }
}